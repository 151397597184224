import React, { useState } from "react";
import { Box } from "@mui/system";
import { Button, TextField, Typography } from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import axios from "axios";

import Golf_BG from './../Assets/Golf_BG.jpg';
import Travel_BG from './../Assets/Travel_BG.png';
import Askara_BG from './../Assets/Askara_BG.png';

import Golf_Logo from './../Assets/Golf_Logo.png';
import Travel_Logo from './../Assets/Travel_Logo.jpg';
import Askara_Logo from './../Assets/Askara_Logo.png';

import { Link } from "react-router-dom";
import { vAPIURL, vBoolGolf, vBoolTravel } from './../Global';
import toastr from "toastr";

const ForgotPassword = () => {
	const [vUsername, setUsername] = useState("");
	const [vEmail, setEmail] = useState("");
	const [vLoading, setLoading] = useState(false);

	let vBG = Askara_BG;
	let vLogo = Askara_Logo;
	let vTitle = "ASKARA";

	if( vBoolGolf && vBoolTravel ){
	}
	else if( vBoolGolf ){
		vBG = Golf_BG;
		vLogo = Golf_Logo;
		vTitle = "GM360";
	}
	else if( vBoolTravel ){
		vBG = Travel_BG;
		vLogo = Travel_Logo;
		vTitle = "ITM";
	}

	const getUserForgotPassword = async () => {
		setLoading(true);
		let vStatus = 1;
		let vMessage = [];

		try{
			const vURL = vAPIURL + "/Password/Forgot/Request";
			const vData = {
				Action: {
					Value: "Request",
				},
				Input: {
					Username: {
						Value: vUsername,
					},
					Email: {
						Value: vEmail,
					},
				},
			};
			
			const vConfig = {
				headers: {
					"Content-Type": "application/json",
				},
			};
			await axios 
					.post (vURL, vData, vConfig)
					.then (
							(vResponse) => {
								vStatus = vResponse.data.Status;

								for(var i = 0; i < vResponse.data.Message.length; i++){
									vMessage.push(vResponse.data.Message[i]);
								}
							}
					)
					.catch(
							(err) => {
								vStatus = 2;
								vMessage.push("Error System");
							}
					);
		}catch(error){
				vStatus = 2;
				vMessage.push("Error System");
		}

		if(vMessage.length > 0){
			if(vStatus === 1){
					toastr.success(vMessage.join("</br>"));
			}
			else{
				toastr.error(vMessage.join("</br>"));
			}
		}
		setLoading(false)
	};

	

	return (
		<div
			style={{
				backgroundImage: `url(${vBG})`,
				backgroundPosition: "center",
				backgroundSize: "cover",
				backgroundRepeat: "no-repeat",
				height: "100vh",
				padding: "50px",
			}}
		>
			<div align="center">
				<Box sx={{ mt: 15 }}>
					<div className="login-box">
						<div className="card card-outline card-primary">
							<div className="card-header text-center">
								<div className="row">
									<div className="col-2">
										<img
											src={vLogo}
											className="brand-image img-circle elevation-3"
											style={{ height: 60, opacity: ".8" }}
										/>
									</div>
									<div className="col-8">
										<Typography sx={{ mt: 2 }} variant="h5">
											{vTitle} Dashboard
										</Typography>
									</div>
								</div>
							</div>
							<div className="card-body">
								<h6>
									You forgot your password? <br /> Here you can easily retrieve a{" "}
									<br /> new password
								</h6>
									<TextField
										variant="outlined"
										margin="normal"
										fullWidth
										id="Username"
										name="Username"
										label="Username"
										type="input"
										onChange={(e) => setUsername(e.target.value)}
									/>
									<TextField
										variant="outlined"
										margin="normal"
										fullWidth
										id="Email"
										name="Email"
										label="Email"
										type="input"
										onChange={(e) => setEmail(e.target.value)}
										InputProps={{
											startAdornment: <EmailIcon color="primary" />,
										}}
									/>
									{
											vLoading?
											<Button
											type="submit"
											fullWidth
											variant="contained"
											color="primary"
									>
											<center>
													<i className="fas fa-1x fa-sync-alt fa-spin">
													</i>
												</center>
											</Button>
									:
										<>
											<Button
													type="submit"
													fullWidth
													variant="contained"
													color="primary"
													onClick={getUserForgotPassword}
											>
													Request New Password
											</Button>
											<Link
												to="/Password/Forgot/Confirm"
											>
												Click here to confirm we send <br /> Code to your email.
											</Link>
										</>
									}
							</div>
						</div>
					</div>
				</Box>
			</div>
		</div>
	);
};

export default ForgotPassword;